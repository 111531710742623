<template>
  <div class="container">
    <Title title="Page Not Found" />
    <div class="content">
      <h2>Uh-oh! We could not find this page.</h2>
    </div>
  </div>
</template>

<script>
import Title from "@/components/Title.vue";

export default {
  components: {
    Title,
  },
};
</script>

<style lang="scss" scoped>
body {
  height: 100vh;
}

.content {
  margin: 0em 1em;
  width: 70vw;
  font-family: Inter, Helvetica Neue, Helvetica, Arial, sans-serif;
  margin-left: 4vw;
}

h2 {
  color: #009cdf;
}

p {
  line-height: 2em;
}
</style>
