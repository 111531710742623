<template>
  <div id="container">
    <h1 class="title">{{ title.toUpperCase() }}</h1>
    <hr />
  </div>
</template>

<script>
export default {
  props: ["title"],
};
</script>

<style lang="scss" scoped>
.title {
  font-size: 4rem;
  font-family: Inter, Helvetica Neue, Helvetica, Arial, sans-serif;
  color: #2f3446;
  margin: 0;
  padding: 4vw;
  @media only screen and (max-width: 900px) {
    font-size: 10vw;
  }
}

hr {
  width: 90%;
  margin-left: 4vw;
  size: 0;
  padding: 0;
  margin-bottom: 5rem;
  border-spacing: 0;
  border: 0;
  border-top: 2px solid black;
}
</style>
